import { gql } from "@apollo/client";
import { Classes } from "@blueprintjs/core";
import { DOCS_LINKS, OrgRole, isOrgRoleSuperset } from "@hex/common";
import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import {
  HexButton,
  HexCheckbox,
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
  HexPopover,
  HexTooltip,
  Txt,
} from "../../hex-components";
import { BUTTON_OVERRIDE_SMALL } from "../../hex-components/HexButton.js";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import {
  MinimalCategory,
  MinimalStatus,
} from "../../hooks/useProjectLabelsForHex";
import { useGetCategoriesAndStatusesQuery } from "../../hooks/useProjectLabelsForHex.generated";
import { ORG_ID } from "../../orgs";
import { DocsLink } from "../common/DocsLink.js";
import { AppliedCategories } from "../common/labels/applied-labels/AppliedCategories";
import { CollapsedAppliedLabelsWrapper } from "../common/labels/applied-labels/styled.js";
import { CategoryLabel } from "../common/labels/CategoryLabel";
import { StatusLabel } from "../common/labels/StatusLabel";
import {
  InfoIcon,
  LockIcon,
  ReviewInProgressIcon,
  SingleChevronDownIcon,
} from "../icons/CustomIcons";

const DROPDOWN_WIDTH = 120;
export const STATUS_LABEL = "status-label";
export const CATEGORIES_LABEL = "categories-label";

gql`
  fragment StatusFragment on Status {
    id
    name
    color
    description
    inLibrary
    endorsed
    icon
  }

  fragment CategoryFragment on Category {
    id
    name
    color
    description
  }
`;

export interface ProjectStatusProps {
  allStatuses: readonly (MinimalStatus & { enforcesReview: boolean })[];
  appliedStatus?: MinimalStatus | null;
  disabled?: boolean;
  muted?: boolean;
  onSelectStatus: (newStatus: MinimalStatus | null) => void;
  readonly?: boolean;
  resourceType: "hex" | "data";
}

export const ProjectStatus: React.FunctionComponent<ProjectStatusProps> = ({
  allStatuses,
  appliedStatus,
  disabled = false,
  muted = false,
  onSelectStatus,
  readonly = false,
  resourceType,
}) => {
  const currentUser = useCurrentUser();

  const nonEndorsedStatuses = useMemo(
    () => allStatuses.filter((o) => !o.endorsed),
    [allStatuses],
  );

  const endorsedStatuses = useMemo(
    () => allStatuses.filter((o) => o.endorsed),
    [allStatuses],
  );

  const isManagerOrAbove =
    currentUser != null &&
    isOrgRoleSuperset(currentUser.orgRole, OrgRole.MANAGER);

  const labelOptions = useMemo(
    () => (
      <>
        {nonEndorsedStatuses.map((status) => {
          return (
            <LabelOption
              key={status.name}
              active={appliedStatus?.id === status.id}
              text={
                <StatusLabel
                  descriptionTooltipProps={{ placement: "right" }}
                  status={status}
                />
              }
              // eslint-disable-next-line react/jsx-no-bind -- passing status
              onClick={() => onSelectStatus(status)}
            />
          );
        })}
        {endorsedStatuses.length > 0 && (
          <>
            <EndorsedStatusHeader isManagerOrAbove={isManagerOrAbove} />
            {endorsedStatuses.map((status) => {
              return (
                <LabelOption
                  key={status.name}
                  active={appliedStatus === status}
                  disabled={!isManagerOrAbove}
                  text={
                    <EndorsedStatusRow
                      resourceType={resourceType}
                      status={status}
                    />
                  }
                  // eslint-disable-next-line react/jsx-no-bind -- passing status
                  onClick={() => onSelectStatus(status)}
                />
              );
            })}
          </>
        )}
      </>
    ),
    [
      appliedStatus,
      endorsedStatuses,
      isManagerOrAbove,
      nonEndorsedStatuses,
      onSelectStatus,
      resourceType,
    ],
  );

  const clearStatus = useCallback(() => onSelectStatus(null), [onSelectStatus]);

  if ((disabled || readonly) && !appliedStatus) {
    return null;
  }

  return (
    <LabelSection className={STATUS_LABEL}>
      <HexPopover
        captureDismiss={true}
        content={
          <LabelOptionsList>
            {appliedStatus && (
              <>
                <HexMenuDivider />
                <LabelOption
                  intent="danger"
                  text="Clear status"
                  onClick={clearStatus}
                />
              </>
            )}
            {labelOptions}
          </LabelOptionsList>
        }
        disabled={disabled || readonly}
        minimal={true}
        placement="bottom-start"
      >
        <PickerButton
          $noSelection={!appliedStatus}
          $readonly={readonly}
          className={Classes.INPUT}
          disabled={disabled}
          minimal={true}
          rightIcon={
            !appliedStatus ? <SingleChevronDownIcon iconSize={14} /> : null
          }
          small={true}
        >
          {appliedStatus ? (
            <StatusLabel muted={muted} status={appliedStatus} />
          ) : (
            "Add status"
          )}
        </PickerButton>
      </HexPopover>
    </LabelSection>
  );
};

export const EndorsedStatusHeader: React.FunctionComponent<{
  isManagerOrAbove: boolean;
}> = ({ isManagerOrAbove }) => {
  return (
    <HexMenuDivider
      title={
        <HexTooltip
          content={
            <>
              Only workspace admins and managers can apply endorsed statuses.{" "}
              <DocsLink to={DOCS_LINKS.Endorsements}>Learn more.</DocsLink>
            </>
          }
          hoverCloseDelay={200}
          interactionKind="hover"
          placement="right"
        >
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            <div
              css={`
                margin-right: 3px;
              `}
            >
              Endorsed Statuses
            </div>
            {isManagerOrAbove ? <InfoIcon /> : <LockIcon />}
          </div>
        </HexTooltip>
      }
    />
  );
};

export const EndorsedStatusRow: React.FunctionComponent<{
  status: MinimalStatus & { enforcesReview: boolean };
  resourceType: "hex" | "data";
  className?: string;
}> = ({ className, resourceType, status }) => {
  const enforcesReview = resourceType === "hex" && status.enforcesReview;

  return (
    <HexTooltip
      content={
        <div
          css={css`
            flex-direction: column;
            display: flex;
            gap: 3px;
          `}
        >
          {status?.description != null && status?.description !== "" && (
            <>
              <Txt fontSize="small">{status.description}</Txt>
              <br />
            </>
          )}
          <Txt fontColor="muted" fontSize="extra_small">
            <LockIcon iconSize={8} /> Only editable by workspace admins &
            managers
          </Txt>
          {enforcesReview && (
            <Txt fontColor="muted" fontSize="extra_small">
              <ReviewInProgressIcon iconSize={8} /> Review required for
              publishing
            </Txt>
          )}
        </div>
      }
      // eslint-disable-next-line react/jsx-no-bind -- render target
      renderTarget={({
        isOpen: _tooltipIsOpen,
        ref: tooltipRef,
        ...tooltipProps
      }) => (
        <div
          {...tooltipProps}
          ref={tooltipRef}
          className={className}
          css={css`
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 3px;
          `}
        >
          <StatusLabel
            descriptionTooltipProps={{ placement: "right" }}
            status={{ ...status, description: null }}
          />
          {enforcesReview && <ReviewInProgressIcon />}
        </div>
      )}
    />
  );
};

interface ProjectCategoriesProps {
  allCategories: readonly MinimalCategory[];
  appliedCategories: readonly MinimalCategory[];
  disabled?: boolean;
  muted?: boolean;
  onSelectCategory: (category: MinimalCategory, applied: boolean) => void;
  readonly?: boolean;
}

export const ProjectCategories: React.FunctionComponent<
  ProjectCategoriesProps
> = ({
  allCategories,
  appliedCategories,
  disabled = false,
  muted = false,
  onSelectCategory,
  readonly = false,
}) => {
  const appliedCategoryIds = useMemo(
    () => appliedCategories.map((c) => c.id),
    [appliedCategories],
  );
  const isCategoryApplied = useCallback(
    (category: MinimalCategory) => {
      return appliedCategoryIds.includes(category.id);
    },
    [appliedCategoryIds],
  );

  if ((disabled || readonly) && appliedCategories.length === 0) {
    return null;
  }

  return (
    <LabelSection className={CATEGORIES_LABEL}>
      <HexPopover
        captureDismiss={true}
        content={
          <LabelOptionsList>
            {allCategories.map((category) => {
              return (
                <LabelOption
                  key={category.name}
                  // shouldDismissPopover={false}
                  text={
                    <StyledCheckbox
                      checked={isCategoryApplied(category)}
                      labelElement={
                        <CategoryLabel
                          category={category}
                          descriptionTooltipProps={{
                            placement: "right",
                          }}
                        />
                      }
                    />
                  }
                  // eslint-disable-next-line react/jsx-no-bind -- in a loop
                  onClick={() =>
                    onSelectCategory(category, isCategoryApplied(category))
                  }
                />
              );
            })}
          </LabelOptionsList>
        }
        disabled={disabled || readonly}
        minimal={true}
        placement="bottom-start"
      >
        <PickerButton
          $noSelection={appliedCategories.length === 0}
          $readonly={readonly}
          className={Classes.INPUT}
          disabled={disabled}
          minimal={true}
          rightIcon={
            appliedCategories.length === 0 ? (
              <SingleChevronDownIcon iconSize={14} />
            ) : null
          }
          small={true}
        >
          {appliedCategories && appliedCategories.length > 0 ? (
            <AppliedCategoryWrapper>
              <AppliedCategories
                appliedCategories={appliedCategories}
                muted={muted}
              />
            </AppliedCategoryWrapper>
          ) : (
            "Add categories"
          )}
        </PickerButton>
      </HexPopover>
    </LabelSection>
  );
};

interface ProjectLabelsProps {
  appliedCategories: readonly MinimalCategory[];
  appliedStatus: MinimalStatus | null;
  display?: "all" | "categories" | "status";
  disabled?: boolean;
  muted?: boolean;
  onSelectCategory: (category: MinimalCategory, applied: boolean) => void;
  onSelectStatus: (newStatus: MinimalStatus | null) => void;
  readonly?: boolean;
  className?: string;
  resourceType: "hex" | "data";
}

export const ProjectLabels: React.FunctionComponent<ProjectLabelsProps> = ({
  appliedCategories,
  appliedStatus,
  className,
  disabled = false,
  display = "all",
  muted = false,
  onSelectCategory,
  onSelectStatus,
  readonly,
  resourceType,
}) => {
  const { data, loading } = useGetCategoriesAndStatusesQuery({
    variables: { orgId: ORG_ID },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const allOrgCategories = useMemo(
    () => [...(data?.orgById.categories ?? [])],
    [data],
  );

  const allOrgStatuses = useMemo(
    () => [...(data?.orgById.statuses ?? [])],
    [data],
  );

  const onSelectStatusCallback = useCallback(
    (newStatus: MinimalStatus | null) => {
      onSelectStatus(newStatus);
    },
    [onSelectStatus],
  );

  const preventNav = useCallback(
    (evt) => {
      if (!readonly) {
        evt.preventDefault();
      }
    },
    [readonly],
  );

  if (loading) {
    return <LoadingLabels />;
  } else if (allOrgStatuses.length === 0 && allOrgCategories.length === 0) {
    return null;
  }

  if (display === "categories" || allOrgStatuses.length === 0) {
    if (readonly && appliedCategories.length === 0) {
      return null;
    }
    return (
      <div onClick={preventNav}>
        <ProjectCategories
          allCategories={allOrgCategories}
          appliedCategories={appliedCategories}
          disabled={disabled}
          muted={muted}
          readonly={readonly}
          onSelectCategory={onSelectCategory}
        />
      </div>
    );
  }

  if (display === "status" || allOrgCategories.length === 0) {
    if (readonly && !appliedStatus) {
      return null;
    }
    return (
      <div onClick={preventNav}>
        <ProjectStatus
          allStatuses={allOrgStatuses}
          appliedStatus={appliedStatus}
          disabled={disabled}
          muted={muted}
          readonly={readonly}
          resourceType={resourceType}
          onSelectStatus={onSelectStatusCallback}
        />
      </div>
    );
  }

  if (readonly && appliedCategories.length === 0 && !appliedStatus) {
    return null;
  }

  return (
    <LabelLayout className={className} onClick={preventNav}>
      <ProjectStatus
        allStatuses={allOrgStatuses}
        appliedStatus={appliedStatus}
        disabled={disabled}
        muted={muted}
        readonly={readonly}
        resourceType={resourceType}
        onSelectStatus={onSelectStatusCallback}
      />
      <ProjectCategories
        allCategories={allOrgCategories}
        appliedCategories={appliedCategories}
        disabled={disabled}
        muted={muted}
        readonly={readonly}
        onSelectCategory={onSelectCategory}
      />
    </LabelLayout>
  );
};

const LoadingLabels = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  height: 24px;
  padding-left: 3px;

  &::before,
  &::after {
    display: block;
    width: 60px;
    height: 3px;
    margin-right: 15px;

    background-color: ${({ theme }) => theme.activeColor};
    border-radius: ${({ theme }) => theme.borderRadius};

    content: "";
  }
`;

const LabelLayout = styled.div`
  display: flex;
  min-width: 0;
  transition: background-color ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};
`;

export const LabelSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 0;
  padding: 0;

  &.${STATUS_LABEL} {
    flex-shrink: 0;
  }

  &.${CATEGORIES_LABEL} {
    flex-shrink: 1;
  }

  .${Classes.POPOVER_TARGET} {
    display: flex;
    min-width: 0;
  }
`;

const PickerButton = styled(HexButton)<{
  $readonly: boolean;
  $noSelection: boolean;
}>`
  &&&&&&&&${BUTTON_OVERRIDE_SMALL} {
    justify-content: space-between;
    width: 100%;
    height: auto;
    outline: none;
    padding: 0;

    .${Classes.BUTTON_TEXT} {
      min-width: 0;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    ${({ $noSelection }) =>
      $noSelection &&
      css`
        padding: 4px 6px;
        gap: 2px;
      `}

    ${({ $readonly }) =>
      $readonly &&
      css`
        &&&.${Classes.BUTTON} {
          &&.${Classes.BUTTON}:not(.${Classes.DISABLED}):hover {
            background-color: transparent;
          }
        }
      `}


      /** Spacing for collapsed categories wrapper, that is needed for the picker button  */
     &&&& ${CollapsedAppliedLabelsWrapper} {
      padding: 4px 6px;
    }
  }
`;

export const LabelOptionsList = styled(HexMenu)`
  min-width: ${DROPDOWN_WIDTH}px;
`;

export const LabelOption = styled(HexMenuItem)`
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  padding-top: 0;
  padding-bottom: 0;
`;

export const AppliedCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: -4px 0;
`;

const StyledCheckbox = styled(HexCheckbox)`
  display: flex;
  align-items: center;

  .${Classes.CONTROL_INDICATOR} {
    margin-top: 0;
  }
  .${Classes.POPOVER_TARGET} {
    display: flex;
  }
`;
